const winAny = (window as any)
winAny.appFlyout ??= {}
winAny.appFlyout.init ??= initAppFlyout

const flyoutUnderlay = document.querySelector('.app-flyout-underlay');
const flyoutWrapper = document.querySelector('.app-flyout-iframe-wrapper');
const flyoutIframe = flyoutWrapper.querySelector('iframe');
const flyoutWrapperClose = flyoutWrapper.querySelector('.close');
const flyoutLinks = document.querySelectorAll('a[data-app-flyout]:not(.app-flyout-demo)');
const table = document.getElementById('app-flyout-links')

function initAppFlyout({ domAttribute, skinName } : { domAttribute: string, skinName: string }) {
	if(document.querySelector('body').classList.contains('role-admin')) {
		createDialogLinksList();

		if(flyoutLinks.length === 0) {
			document.querySelector('.app-flyout-none').classList.add('is-visible');
		} else {
			document.querySelector('.app-flyout-found').classList.add('is-visible');
		}		
	}


	
	document.addEventListener('click', function(e){
		const flyoutLink: HTMLElement = (e.target as HTMLElement).closest('a[data-app-flyout]');
	
		if(flyoutLink){
			e.preventDefault();
			showDialog(flyoutLink, skinName);
		}
	})
	
	if(flyoutWrapperClose != null) {
		flyoutWrapperClose.addEventListener('click', (e) => {
			e.preventDefault();
			hideDialog()
		})
	}
	
	if(flyoutUnderlay != null) {
		flyoutUnderlay.addEventListener('click', (e) => {
			e.preventDefault();
			hideDialog()
		})
	}
}

function createDialogLinksList() {
	flyoutLinks.forEach((dialogLink, index) => {
		const tr = document.createElement('tr');
		const td = document.createElement('td');
		const a = document.createElement('a');

		const clone = dialogLink.cloneNode(true) as HTMLElement;		
		clone.style.textTransform = 'none';
		
		a.setAttribute('href', clone.getAttribute('href'));
		a.setAttribute('data-app-flyout', '');
		a.classList.add('text-dark')
		a.innerText = clone.innerText.trim().slice(0, 15) + (clone.innerText.length > 15 ? ' ...' : '');

		td.appendChild(a);
		tr.appendChild(td);

		table.querySelector('tbody').appendChild(tr);
	})
}

function showDialog(elem: HTMLElement, skinName: string) {
	const iframeLink = elem.getAttribute('href');
	document.querySelector('body').classList.add('disable-scroll');

	flyoutIframe.setAttribute('src', `${iframeLink}?SkinSrc=[G]Skins/${skinName}/skeleton`);
	flyoutWrapper.classList.add('is-open');
	flyoutUnderlay.classList.add('is-visible');
}

function hideDialog() {
	document.querySelector('body').classList.remove('disable-scroll');

	flyoutIframe.setAttribute('src','');
	flyoutWrapper.classList.remove('is-open');
	flyoutUnderlay.classList.remove('is-visible');
}
